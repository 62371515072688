import React from "react"
import PropTypes from "prop-types"
import { FaCheck } from 'react-icons/fa'
import {useDispatch, useSelector} from "react-redux"

import {isDefined} from "../../../utils/data"
import {getAmount} from "../../../redux/group/groupAction"

const YourOrder = ({members, total, amountMembers}) => {
    const dispatch      = useDispatch()
    const {coordinator} = useSelector(({groupReducer}) => groupReducer)

    return (
        <div className={"sr-card summary-block your-order"}>
            <div className={"summary-header"}>
                Group members :
            </div>
            <hr/>
            <div className={"summary-body"}>
                {members.map((member, i) => {
                    let amount = 0
                    const category  = member.registered.registered_category
                    const ticket    = isDefined(member.registered_package.idpackages) ? member.registered_package.idpackages : member.registered_package.idpackage
                    const options   = member.registered_option.map(option => {
                        return {
                            idoptions: option.idoptions
                        }
                    })


                    if(isDefined(member.amount))
                        amount = member.amount
                    else if(isDefined(member.registered.registered_invoice))
                        amount = parseFloat(member.registered.registered_invoice)
                    else {
                        dispatch(getAmount(category, ticket, options)).then(res => {
                            amount = res
                        })
                    }

                    return (
                        <div key={i} className={"row ticket"}>
                            <div className={"col-lg-1"}>
                                <FaCheck/>
                            </div>
                            <div className={"col-lg-8 summary-name"}>
                                {member.registered.registered_firstname + " " + member.registered.registered_lastname}
                            </div>
                            <div className={"col-lg-3 prices"}>
                                {(isDefined(amountMembers) ? amountMembers[i] : amount) + " €"}
                            </div>
                        </div>
                    )
                })}
            </div>
            <hr/>
            <div className={"summary-footer"}>
                
                <div className={"row total"}>
                    <div className={"col-lg-6 align-left"}>
                        Total
                    </div>
                    <div className={"col-lg-6 prices"}>
                        {isDefined(coordinator) ? parseFloat(total) + " €"
                        :  parseFloat(total) + " €"}

                    </div>
{members.length > 9 && members.length < 20 ?
    <>
                    <div className={"col-lg-6 align-left"}>
                        Discount 10%
                    </div>
                    <div className={"col-lg-6 prices"}>
                        {parseFloat(total) * 10 / 100}  €

                    </div>
</>
: ""}
{members.length > 19 ?
    <>
                    <div className={"col-lg-6 align-left"}>
                        Discount 20%
                    </div>
                    <div className={"col-lg-6 prices"}>
                        {parseFloat(total) * 20 / 100} €

                    </div>
</>
: ""}
{isDefined(coordinator) ? isDefined(coordinator.group.group_payments) ?
                    parseFloat(coordinator.group.group_payments) > 0 && parseFloat(coordinator.group.group_payments) < parseFloat(total) ?    
                        <>
                            <div className={"col-lg-6 align-left vat"}>
                                Already pay
                            </div>
                            <div className={"col-lg-6 prices vat"}>
                                ( {parseFloat(coordinator.group.group_payments)} € )
                            </div> 
                        </>
                        : "" : "" : ""}
{members.length > 9 && members.length < 20 ?
    <>
                    <div className={"col-lg-6 align-left"}>
                        To pay
                    </div>
                    <div className={"col-lg-6 prices"}>
                        {isDefined(coordinator) ? (parseFloat(total)- ( parseFloat(total) * 10 / 100) - (parseFloat(coordinator.group.group_payments) || 0)).toFixed(2) + " €"
                        :  parseFloat(total)- ( parseFloat(total) * 10 / 100) + " €"}
                    </div>
</>
: ""}
{members.length > 19 ?
    <>
                    <div className={"col-lg-6 align-left"}>
                         To pay
                    </div>
                    <div className={"col-lg-6 prices"}>
                        {isDefined(coordinator) ? (parseFloat(total)- ( parseFloat(total) * 20 / 100) - (parseFloat(coordinator.group.group_payments) || 0)).toFixed(2) + " €"
                        :  parseFloat(total)- ( parseFloat(total) * 20 / 100) + " €"}
                    </div>
</>
: ""}
                </div>
            </div>
        </div>
    )
}

YourOrder.propTypes = {
    members:     PropTypes.array.isRequired,
    total:      PropTypes.string.isRequired,
    amountMembers: PropTypes.array
}

export default YourOrder
